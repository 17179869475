import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
mode: 'history',
//base: '/ctshub_test/',
base: '/ctshub/',
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue")
        },
        {
          path: "/customer/list",
          name: "customers",
          component: () => import("@/view/layout/customer/index.vue")
        },
        {
          path: "/customer/view/:customer_id",
          name: "view-customer",
          component: () => import("@/view/layout/customer/view.vue")
        },
        {
          path: "/customer/edit/:customer_id",
          name: "edit-customer",
          component: () => import("@/view/layout/customer/addedit.vue")
        },
        {
          path: "/customer/create",
          name: "add-customer",
          component: () => import("@/view/layout/customer/addedit.vue")
        },
        {
          path: "/customer/import",
          name: "import-customer",
          component: () => import("@/view/layout/customer/ImportCustomer.vue")
        },
        {
          path: "/customer/order",
          name: "oredr-details",
          component: () => import("@/view/layout/customer/order-details.vue")
        },        
        {
          path: "/customer/packingslip/:order_id/:customer_id",
          name: "customer-packing",
          component: () => import("@/view/layout/customer/packingslip.vue")
        }, 
        {
          path: "/customer/credit",
          name: "customer-credit",
          component: () => import("@/view/layout/customer/credit-list.vue")
        },       
        {
          path: "/credit/memo/:credit_memo_id",
          name: "credit_memo_view",
          component: () => import("@/view/layout/creditMemo/credit_memo.vue")
        },
        {
          path: "cancel/credit/memo/:credit_memo_id",
          name: "cancel_credit_memo_view",
          component: () => import("@/view/layout/creditMemo/cancel_credit_memo.vue")
        },  
        {
          path: "/credit/memo/list",
          name: "credit_memo_list",
          component: () => import("@/view/layout/customer/credit_memo_list.vue")
        },  
        {
          path: "/credit/card",
          name: "card-credit",
          component: () => import("@/view/layout/paymentMethod/credit_card_details.vue")
        },  
        {
          path: "add/card/details",
          name: "add_card",
          component: () => import("@/view/layout/paymentMethod/add_cardDetails.vue")
        },      
        {
          path: "/product/create",
          name: "product-create",
          component: () => import("@/view/layout/product/addedit.vue")
        },
        {
          path: "/product/import/csv",
          name: "product-csv",
          component: () => import("@/view/layout/product/importproduct.vue")
        },

        {
          path: "/product/imports",
          name: "product-imports",
          component: () => import("@/view/layout/product/ImportProducts.vue")
        },
        {
          path: "/product/dashboard",
          name: "product-dashboard",
          component: () => import("@/view/layout/product/productdashboard.vue")
        },
        {
          path: "/product/edit/:product_id",
          name: "product-edit",
          component: () => import("@/view/layout/product/addedit.vue"),
          props:true
        },
        {
          path: "/product/list",
          name: "product-list",
          component: () => import("@/view/layout/product/index.vue")
        },
        {
          path: "/product/view/:product_id",
          name: "product-view",
          component: () => import("@/view/layout/product/view.vue")
        },
        {
          path: "/product/add-category",
          name: "add-category",
          component: () => import("@/view/layout/product/addeditcategory.vue")
        },
        {
          path: "/product/categories",
          name: "product-categories",
          component: () => import("@/view/layout/product/categories.vue")
        },
        {
          path: "/product/category/:cat_id",
          name: "category-view",
          component: () => import("@/view/layout/product/viewcategory.vue")
        },
        {
          path: "/product/edit-category/:cat_id",
          name: "edit-category",
          component: () => import("@/view/layout/product/addeditcategory.vue")
        },

        {
          path: "/product/add-brand",
          name: "add-brand",
          component: () => import("@/view/layout/product/addeditbrand.vue")
        },
        {
          path: "/product/brands",
          name: "product-brands",
          component: () => import("@/view/layout/product/brand.vue")
        },
        {
          path: "/product/brand/:brand_id",
          name: "brand-view",
          component: () => import("@/view/layout/product/viewbrand.vue")
        },
        {
          path: "/product/edit-brand/:brand_id",
          name: "edit-brand",
          component: () => import("@/view/layout/product/addeditbrand.vue")
        },
        {
          path: "/product/import",
          name: "product-import",
          component: () => import("@/view/layout/product/importproduct.vue")
        },
        {
          path: "/remove/product/list",
          name: "remove-product-list",
          component: () => import("@/view/layout/removeProduct/removeProductList.vue")
        },
        {
          path: "/removeProduct/add/list/:product_id",
          name: "remove-product-add-list",
          component: () => import("@/view/layout/removeProduct/addedit.vue")
        },
        
        {
          path: "/temporaryproducts/list",
          name: "temporaryproductList",
          component: () => import("@/view/layout/temporaryProducts/tempproductlist.vue")
        },
        {
          path: "/posproducts/list",
          name: "posproductList",
          component: () => import("@/view/layout/posProducts/posproductlist.vue")
        },
        {
          path: "/posproduct/create",
          name: "posproduct-create",
          component: () => import("@/view/layout/posProducts/addedit.vue")
        },
        {
          path: "/posproduct/edit/:product_id",
          name: "posproduct-edit",
          component: () => import("@/view/layout/posProducts/addedit.vue"),
          props:true
        },
        {
          path: "/posproduct/view/:product_id",
          name: "posproduct-view",
          component: () => import("@/view/layout/posProducts/view.vue")
        },
        {
          path: "/temporary/move/product",
          name: "temporaryproducts",
          component: () => import("@/view/layout/temporaryProducts/tempmoveproductlist.vue")
        },
        {
          path: "/product/download",
          name: "product-download",
          component: () => import("@/view/layout/product/productdownload.vue")
        },
        {
          path: "/employee/list",
          name: "employee",
          component: () => import("@/view/layout/employee/index.vue")
        },
        {
          path: "/employee/create",
          name: "addedit-employee",
          component: () => import("@/view/layout/employee/addedit.vue")
        },
        {
          path: "/employee/edit/:employeeId",
          name: "edit-employee",
          component: () => import("@/view/layout/employee/addedit.vue")
        },
        {
          path: "/employee/view/:employeeId",
          name: "view-employee",
          component: () => import("@/view/layout/employee/view.vue")
        },
        {
          path: "/website/list",
          name: "website-list",
          component: () => import("@/view/layout/websitesettings/index.vue")
        },
        {
          path: "/website/create",
          name: "add-website",
          component: () => import("@/view/layout/websitesettings/addedit.vue")
        },
        {
          path: "/website/edit/:websiteId",
          name: "edit-website",
          component: () => import("@/view/layout/websitesettings/addedit.vue")
        },
        {
          path: "/website/view/:websiteId",
          name: "view-website",
          component: () => import("@/view/layout/websitesettings/view.vue")
        },
        {
          path: "/settings",
          name: "settings",
          component: () => import("@/view/layout/settings/settings.vue")
        },
        {
          path: "/changepassword",
          name: "changepassword",
          component: () => import("@/view/layout/settings/changepassword.vue")
        },
        {
          path: "/role/list",
          name: "list-role",
          component: () => import("@/view/layout/settings/role/index.vue")
        },
        {
          path: "/role/create",
          name: "add-role",
          component: () => import("@/view/layout/settings/role/addedit.vue")
        },
        {
          path: "/role/edit/:roleId",
          name: "edit-role",
          component: () => import("@/view/layout/settings/role/addedit.vue")
        },
        {
          path: "/module/list",
          name: "list-module",
          component: () => import("@/view/layout/settings/module/index.vue")
        },
        {
          path: "/module/create",
          name: "add-module",
          component: () => import("@/view/layout/settings/module/addedit.vue")
        },
        {
          path: "/module/edit/:moduleId",
          name: "edit-module",
          component: () => import("@/view/layout/settings/module/addedit.vue")
        },
        {
          path: "/screen/list",
          name: "list-screen",
          component: () => import("@/view/layout/settings/screen/index.vue")
        },
        {
          path: "/screen/create",
          name: "add-screen",
          component: () => import("@/view/layout/settings/screen/addedit.vue")
        },
        {
          path: "/screen/edit/:screenId",
          name: "edit-screen",
          component: () => import("@/view/layout/settings/screen/addedit.vue")
        },
        {
          path: "/role/privilege/:roleId",
          name: "permission",
          component: () => import("@/view/layout/settings/privilege/index.vue")
        },
        {
          path: "/supplier/list",
          name: "supplier",
          component: () => import("@/view/layout/supplier/index.vue")
        },
        {
          path: "/supplier/create",
          name: "add-supplier",
          component: () => import("@/view/layout/supplier/addedit.vue")
        },
        {
          path: "/supplier/edit/:supplierId",
          name: "edit-supplier",
          component: () => import("@/view/layout/supplier/addedit.vue")
        },
        {
          path: "/supplier/view/:supplierId",
          name: "view-supplier",
          component: () => import("@/view/layout/supplier/view.vue")
        },
        {
          path: "supplier/product/view/:product_id",
          name: "supplier_product_view",
          component: () => import("@/view/layout/supplier/productView.vue"),
          props : true
        },
        {
          path: "supplier/product/edit/:product_id",
          name: "supplier_product_edit",
          component: () => import("@/view/layout/supplier/productAddedit.vue"),
          props : true
        },
        {
          path: "/supplier/import",
          name: "import-supplier",
          component: () => import("@/view/layout/supplier/ImportSupplier.vue")
        },
        {
          path: "/purchase-order/list",
          name: "purchase-order",
          component: () => import("@/view/layout/purchaseorder/index.vue")
        },       
        {
          path: "/all/purchase/order",
          name: "all PO",
          component: () => import("@/view/layout/purchaseorder/index.vue")
        },
        {
          path: "/supplier/import/price",
          name: "import-supplier_price",
          component: () => import("@/view/layout/supplier/importSupplierPrice.vue")
        },
        {
          path: "/complete/purchase/order",
          name: "complete_po",
          component: () => import("@/view/layout/purchaseorder/complete_purchase_order.vue")
        },
        {
          path: "/purchase-order/create",
          name: "create-purchase-order",
          component: () => import("@/view/layout/purchaseorder/addedit.vue"),
          props: true
        },
        {
          path: "/purchase-order/edit/:poId",
          name: "edit-purchase-order",
          component: () => import("@/view/layout/purchaseorder/addedit.vue")
        },
        {
          path: "/purchase-order/view/:poId",
          name: "view-purchase-order",
          component: () => import("@/view/layout/purchaseorder/view.vue")
        },
        {
          path: "/back-purchase-order/view/:backorder_id",
          name: "view-back-purchase-order",
          component: () => import("@/view/layout/purchaseorder/back_purchaseorder_view.vue")
        },
        {
          path: "/request/purchase/order",
          name: "request_po",
          component: () => import("@/view/layout/purchaseorder/request_po.vue")
        },
        {
          path: "/recive/purchase/order",
          name: "receive_po_view",
          component: () => import("@/view/layout/purchaseorder/receive_purchaseorder.vue")
        },
        {
          path: "/recive/purchase_order/list",
          name: "receive_po_list",
          component: () => import("@/view/layout/purchaseorder/receive_purchaseorder_list.vue")
        },
        {
          path: "/recive/purchase/order/:po_receive_id",
          name: "receive_po",
          component: () => import("@/view/layout/purchaseorder/receive_po_view.vue")
        },
        {
          path: "/recive/purchase/order/list",
          name: "receive_po_list_view",
          component: () => import("@/view/layout/purchaseorder/receive_purchaseorder.vue")
        },
        {
          path: "add/recive/purchase/order/:poId",
          name: "add_receive_po",
          component: () => import("@/view/layout/purchaseorder/add_receive_po.vue")
        },
        {
          path: "/purchase/back/order",
          name: "po_backorder",
          component: () => import("@/view/layout/purchaseorder/po_backorders.vue")
        },
        {
          path: "/purchase/cancel/order/:poId",
          name: "po_cancel_order",
          component: () => import("@/view/layout/purchaseorder/po_cancelorder.vue")
        },
        {
          path: "/cancel_purchaseorder_view/view/:cancel_id",
          name: "po_cancel_order_view",
          component: () => import("@/view/layout/purchaseorder/cancel_purchaseorder_view.vue")
        },
        {
          path: "/purchaseOrder/waiting/list",
          name: "waiting-purchase-order",
          component: () => import("@/view/layout/purchaseorder/waitingList.vue")
        },
        {
          path: "/purchaseOrder/processing/list",
          name: "purchase-order-list",
          component: () => import("@/view/layout/purchaseorder/processingList.vue")
        },
        {
          path: "/order/list",
          name: "order",
          component: () => import("@/view/layout/order/index.vue")
        },
        {
          path: "/order/processing",
          name: "processing",
          component: () => import("@/view/layout/processing/index.vue")
        },
        {
          path: "/driver/update/delivery",
          name: "delivery",
          component: () => import("@/view/layout/deliveryStatus/driver-update.vue")
        },
        {
          path: "all/order/list",
          name: "all_order",
          component: () => import("@/view/layout/order/allorders.vue")
        },
        {
          path: "/order/create",
          name: "create-order",
          //component: () => import("@/view/layout/order/addedit.vue")
          component: () => import("@/view/layout/order/addOrder.vue")
        },
        {
          path: "/order/creates",
          name: "create-orders",
          component: () => import("@/view/layout/order/addedit.vue")
        },
        {
          path: "/order/edit/:orderId",
          name: "create-edit",
          component: () => import("@/view/layout/order/editOrder.vue")
        },
       
        {
          path: "/order/view/:order_id",
          name: "view-order",
          component: () => import("@/view/layout/order/view.vue")

        }, 
        {
          path: "/return/product",
          name: "return-product",
          component: () => import("@/view/layout/ReturnProduct/return-product.vue")

        }, 
        {
          path: "/return/add",
          name: "return-add",
          component: () => import("@/view/layout/ReturnProduct/add-return.vue")

        }, 
        {
          path: "/return/view/:return_id",
          name: "return-view",
          component: () => import("@/view/layout/ReturnProduct/return_view.vue")

        }, 
        {
          path: "/invoice/list",
          name: "invoice-list",
          component: () => import("@/view/layout/invoice/index.vue")

        },  
        {
          path: "/invoice/create",
          name: "invoice-create",
          component: () => import("@/view/layout/invoice/create.vue")

        },  
        {
          path: "/invoice/edit/:order_id",
          name: "invoice-edit",
          component: () => import("@/view/layout/invoice/invoiceEdit.vue")

        },  
        {
          path: "/invoice/view/:order_id",
          name: "invoice-view",
          component: () => import("@/view/layout/invoice/view.vue")

        }, 
        {
          path: "/invoice/purchase/:order_id",
          name: "view-purchase",
          component: () => import("@/view/layout/invoice/purchase.vue")

        }, 
        {
          path: "/invoice/confirm/:order_id",
          name: "view-confirm",
          component: () => import("@/view/layout/invoice/confirm-order.vue")

        }, 
       
        {
          path: "/invoice/packingSlip/:order_id",
          name: "view-packingSlip",
          component: () => import("@/view/layout/invoice/packingslip.vue")

        },  
        {
          path: "/quotation/list",
          name: "list-quotation",
          component: () => import("@/view/layout/quotation/index.vue")

        },
        {
          path: "/quotation/add",
          name: "add-quotation",
          component: () => import("@/view/layout/quotation/addEdit.vue")

        }, 
        {
          path: "/quotation/view/:quotation_id",
          name: "view-quotation",
          component: () => import("@/view/layout/quotation/view.vue")

        },  
        {
          path: "/quotation/edit/:quotation_id",
          name: "edit-quotation",
          component: () => import("@/view/layout/quotation/addEdit.vue")

        }, 
        {
          path: "/convert/quotation/order/:quotation_id",
          name: "quotation_convert_order",
          component: () => import("@/view/layout/quotation/convertorder.vue")

        },       
        {
          path: "/convert/estimation/order/:estimation_id",
          name: "convert_order",
          component: () => import("@/view/layout/estimate/convertorder.vue")

        },
        {
          path: "/estimate/list",
          name: "list-estimate",
          component: () => import("@/view/layout/estimate/index.vue")

        },
        {
          path: "/estimate/add",
          name: "add-estimate",
          component: () => import("@/view/layout/estimate/addEdit.vue")
        }, 
        {
          path: "/estimate/view/:estimate_id",
          name: "view-estimate",
          component: () => import("@/view/layout/estimate/view.vue")
        },  
        {
          path: "/estimate/edit/:estimate_id",
          name: "edit-estimate",
          component: () => import("@/view/layout/estimate/addEdit.vue")
        },           
        {
          path: "/inventory/product",
          name: "inventory-Product",
          component: () => import("@/view/layout/inventory/product-list.vue")
        },
        {
          path: "/inventory/view/:product_id",
          name: "inventory-view",
          component: () => import("@/view/layout/inventory/view.vue")
        },
        {
          path: "/inventory/edit/:product_id",
          name: "inventory-edit",
          component: () => import("@/view/layout/inventory/edit.vue")
        },     
        {
          path: "/notification",
          name: "notification-show",
          component: () => import("@/view/layout/extras/notification/notifications.vue")
        },
        {
          path: "/lowstock/list",
          name: "lowstock-list",
          component: () => import("@/view/layout/inventory/lowstock-list.vue")
        },
        {
          path: "/overstock/list",
          name: "overstock-list",
          component: () => import("@/view/layout/inventory/overStockList.vue")
        },
        {
          path: "/lowstock/view/:product_id",
          name: "lowstock-view",
          component: () => import("@/view/layout/inventory/lowstock-view.vue")
        },

        {
          path: "/clearanceProduct/list",
          name: "clearance_product",
          component: () => import("@/view/layout/inventory/clearanceProductList.vue")
        },
        {
          path: "/packingslip/list",
          name: "packingslip",
          component: () => import("@/view/layout/packingslip/index.vue")
        },
        {
          path: "/packingslip/create/:order_id",
          name: "packingslip-create",
          component: () => import("@/view/layout/packingslip/addedit.vue")
        },
        {
          path: "/packingslip/add",
          name: "packingslipAdd",
          component: () => import("@/view/layout/packingslip/addedit.vue")
        },
        {
          path: "/packingslip/view/:packing_id",
          name: "packingslipView",
          component: () => import("@/view/layout/packingslip/view.vue")
        },

        {
          path: "/packingslip/edit/:packing_id",
          name: "packingslipEdit",
          component: () => import("@/view/layout/packingslip/addedit.vue")
        },
        {
          name: "creditApprove",
          path: "/credit/approve/:credit_id",
          component:()=>import("@/view/pages/credit/creditApprove")
        },
        {
          name: "wordPressOrders",
          path: "/wordpress/orders",
          component:()=>import("@/view/layout/wordpress/view_orders")
        },

        {
          name: "weborders",
          path: "/web/orders",
          component:()=>import("@/view/layout/weborders/index")
        },
        {
          name: "storeorders",
          path: "/store/orders",
          component:()=>import("@/view/layout/storeorders/index")
        },
        {
          name: "completeorders",
          path: "/complete/order/list",
          component:()=>import("@/view/layout/order/completeOrder")
        },
        {
          name: "posorders",
          path: "/pos/orders",
          component:()=>import("@/view/layout/posorders/index")
        },
        {
          name: "orderStatus",
          path: "/order/status",
          component:()=>import("@/view/layout/orderStatus/index")
        },
        {
          name: "orderStatusEdit",
          path: "/order/status/edit/:order_id",
          component:()=>import("@/view/layout/orderStatus/addedit")
        },
        {
          name: "backorderStatus",
          path: "/backorder/status/edit/:backorder_id",
          component:()=>import("@/view/layout/backorders/addedit")
        },
        {
          name: "deliveryStatus",
          path: "/delivery/status",
          component:()=>import("@/view/layout/deliveryStatus/index")
        },
        {
          name: "deliveryView",
          path: "/delivery/view/:order_id",
          component:()=>import("@/view/layout/deliveryStatus/view")
        },        
        {
          name: "backorder_list",
          path: "/back/orders",
          component:()=>import("@/view/layout/backorders/index")
        },
        {
          name: "backorder",
          path: "/backorders/view/:backorder_id",
          component:()=>import("@/view/layout/backorders/view")
        },
        {
          name: "returnorder",
          path: "/return/order/:order_id",
          component:()=>import("@/view/layout/returnorder/addedit")
        },
        {
          name: "returnorder_view",
          path: "/return/order/view/:return_id",
          component:()=>import("@/view/layout/returnorder/return_order_view")
        },
        {
          name: "returnorder_list",
          path: "/return/orders",
          component:()=>import("@/view/layout/returnorder/index")
        },
        {
          name: "backorderedit",
          path: "/back/orders/verify/:order_id",
          component:()=>import("@/view/layout/backorders/addedit")
        },
        {
          name: "lowstock_list",
          path: "/low/stock/orders",
          component:()=>import("@/view/layout/order/lowstockorders"),
          props:true
        },
        {
          name: "unpaid_orders",
          path: "/unpaid/order/list",
          component:()=>import("@/view/layout/order/unpaidOrders"),
          props:true
        },
        {
          name: "lowstock_view",
          path: "/low/stock/order/view/:order_id",
          component:()=>import("@/view/layout/order/lowstockview")
        },
        {
          name: "cancel_add",
          path: "/cancel/order/add/:backorder_id",
          component:()=>import("@/view/layout/cancelOrder/cancel_add")
        },
        {
          name: "order_cancel",
          path: "/cancel/order/:order_id",
          component:()=>import("@/view/layout/cancelOrder/order_cancel")
        },
        {
          name: "cancel_view",
          path: "/cancel/order/view/:cancel_id",
          component:()=>import("@/view/layout/cancelOrder/cancel_view")
        },
        {
          name: "card_details",
          path: "/card/details",
          component:()=>import("@/view/layout/payment/cardDetails")
        },
        {
          path: "/cancel/list",
          name: "cancel_order",
          component: () => import("@/view/layout/cancelOrder/cancel_list.vue")
        },
       
        {
          name: "all_transaction",
          path: "/transaction/report",
          component:()=>import("@/view/layout/report/all_transaction")
        },
        {
          name: "add_bill",
          path: "/create/bill",
          component:()=>import("@/view/layout/addBill/create_bill")
        },
       
        {
          name: "employee_time_sheet_details",
          path: "/employee/time/sheet/details",
          component:()=>import("@/view/employee/employee_time_details"),
          props : true
        },
        {
          name: "employee_time_sheet1",
          path: "/employee/time/sheet",
          component:()=>import("@/view/employee/employee_time_sheet"),
          props : true
        },


        {
          path: "/vue-bootstrap",
          name: "vue-bootstrap",
          component: () =>
            import("@/view/pages/vue-bootstrap/VueBootstrap.vue"),
          children: [
            {
              path: "alert",
              name: "vue-bootstrap-alert",
              component: () => import("@/view/pages/vue-bootstrap/Alert.vue")
            },
            {
              path: "badge",
              name: "vue-bootstrap-badge",
              component: () => import("@/view/pages/vue-bootstrap/Badge.vue")
            },
            {
              path: "button",
              name: "vue-bootstrap-button",
              component: () => import("@/view/pages/vue-bootstrap/Button.vue")
            },
            {
              path: "button-group",
              name: "vue-bootstrap-button-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/ButtonGroup.vue")
            },
            {
              path: "button-toolbar",
              name: "vue-bootstrap-button-toolbar",
              component: () =>
                import("@/view/pages/vue-bootstrap/ButtonToolbar.vue")
            },
            {
              path: "card",
              name: "vue-bootstrap-card",
              component: () => import("@/view/pages/vue-bootstrap/Card.vue")
            },
            {
              path: "carousel",
              name: "vue-bootstrap-carousel",
              component: () => import("@/view/pages/vue-bootstrap/Carousel.vue")
            },
            {
              path: "collapse",
              name: "vue-bootstrap-collapse",
              component: () => import("@/view/pages/vue-bootstrap/Collapse.vue")
            },
            {
              path: "dropdown",
              name: "vue-bootstrap-dropdown",
              component: () => import("@/view/pages/vue-bootstrap/Dropdown.vue")
            },
            {
              path: "embed",
              name: "vue-bootstrap-embed",
              component: () => import("@/view/pages/vue-bootstrap/Embed.vue")
            },
            {
              path: "form",
              name: "vue-bootstrap-form",
              component: () => import("@/view/pages/vue-bootstrap/Form.vue")
            },
            {
              path: "form-checkbox",
              name: "vue-bootstrap-form-checkbox",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormCheckbox.vue")
            },
            {
              path: "form-file",
              name: "vue-bootstrap-form-file",
              component: () => import("@/view/pages/vue-bootstrap/FormFile.vue")
            },
            {
              path: "form-group",
              name: "vue-bootstrap-form-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormGroup.vue")
            },
            {
              path: "form-input",
              name: "vue-bootstrap-form-input",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormInput.vue")
            },
            {
              path: "form-radio",
              name: "vue-bootstrap-form-radio",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormRadio.vue")
            },
            {
              path: "form-select",
              name: "vue-bootstrap-form-select",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormSelect.vue")
            },
            {
              path: "form-textarea",
              name: "vue-bootstrap-form-textarea",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormTextarea.vue")
            },
            {
              path: "image",
              name: "vue-bootstrap-image",
              component: () => import("@/view/pages/vue-bootstrap/Image.vue")
            },
            {
              path: "input-group",
              name: "vue-bootstrap-input-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/InputGroup.vue")
            },
            {
              path: "jumbotron",
              name: "vue-bootstrap-jumbotron",
              component: () =>
                import("@/view/pages/vue-bootstrap/Jumbotron.vue")
            },
            {
              path: "layout-grid-system",
              name: "vue-bootstrap-layout-grid-system",
              component: () =>
                import("@/view/pages/vue-bootstrap/LayoutGridSystem.vue")
            },
            {
              path: "link",
              name: "vue-bootstrap-link",
              component: () => import("@/view/pages/vue-bootstrap/Link.vue")
            },
            {
              path: "list-group",
              name: "vue-bootstrap-list-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/ListGroup.vue")
            },
            {
              path: "media",
              name: "vue-bootstrap-media",
              component: () => import("@/view/pages/vue-bootstrap/Media.vue")
            },
            {
              path: "modal",
              name: "vue-bootstrap-modal",
              component: () => import("@/view/pages/vue-bootstrap/Modal.vue")
            },
            {
              path: "nav",
              name: "vue-bootstrap-nav",
              component: () => import("@/view/pages/vue-bootstrap/Nav.vue")
            },
            {
              path: "navbar",
              name: "vue-bootstrap-navbar",
              component: () => import("@/view/pages/vue-bootstrap/Navbar.vue")
            },
            {
              path: "pagination",
              name: "vue-bootstrap-pagination",
              component: () =>
                import("@/view/pages/vue-bootstrap/Pagination.vue")
            },
            {
              path: "pagination-nav",
              name: "vue-bootstrap-pagination-nav",
              component: () =>
                import("@/view/pages/vue-bootstrap/PaginationNav.vue")
            },
            {
              path: "popover",
              name: "vue-bootstrap-popover",
              component: () => import("@/view/pages/vue-bootstrap/Popover.vue")
            },
            {
              path: "progress",
              name: "vue-bootstrap-progress",
              component: () => import("@/view/pages/vue-bootstrap/Progress.vue")
            },
            {
              path: "spinner",
              name: "vue-bootstrap-spinner",
              component: () => import("@/view/pages/vue-bootstrap/Spinner.vue")
            },
            {
              path: "table",
              name: "vue-bootstrap-table",
              component: () => import("@/view/pages/vue-bootstrap/Table.vue")
            },
            {
              path: "tabs",
              name: "vue-bootstrap-tabs",
              component: () => import("@/view/pages/vue-bootstrap/Tabs.vue")
            },
            {
              path: "toasts",
              name: "vue-bootstrap-toasts",
              component: () => import("@/view/pages/vue-bootstrap/Toasts.vue")
            },
            {
              path: "tooltip",
              name: "vue-bootstrap-tooltip",
              component: () => import("@/view/pages/vue-bootstrap/Tooltip.vue")
            }
          ]
        },
        {
          path: "/vuetify",
          name: "vuetify",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "alerts",
              name: "vuetify-alerts",
              component: () => import("@/view/pages/vuetify/Alerts.vue")
            },
            {
              path: "avatars",
              name: "vuetify-avatars",
              component: () => import("@/view/pages/vuetify/Avatars.vue")
            },
            {
              path: "badges",
              name: "vuetify-badges",
              component: () => import("@/view/pages/vuetify/Badges.vue")
            },
            {
              path: "buttons",
              name: "vuetify-buttons",
              component: () => import("@/view/pages/vuetify/Buttons.vue")
            },
            {
              path: "calendars",
              name: "vuetify-calendars",
              component: () => import("@/view/pages/vuetify/Calendars.vue")
            },
            {
              path: "cards",
              name: "vuetify-cards",
              component: () => import("@/view/pages/vuetify/Cards.vue")
            },
            {
              path: "chips",
              name: "vuetify-chips",
              component: () => import("@/view/pages/vuetify/Chips.vue")
            },
            {
              path: "dialog",
              name: "vuetify-dialog",
              component: () => import("@/view/pages/vuetify/Dialog.vue")
            },
            {
              path: "autocompletes",
              name: "vuetify-autocompletes",
              component: () =>
                import("@/view/pages/vuetify/forms/Autocompletes.vue")
            },
            {
              path: "file-inputs",
              name: "vuetify-file-inputs",
              component: () =>
                import("@/view/pages/vuetify/forms/FileInputs.vue")
            },
            {
              path: "forms",
              name: "vuetify-forms",
              component: () => import("@/view/pages/vuetify/forms/Forms.vue")
            },
            {
              path: "selection-controls",
              name: "vuetify-selection-controls",
              component: () =>
                import("@/view/pages/vuetify/forms/SelectionControls.vue")
            },
            {
              path: "selects",
              name: "vuetify-selects",
              component: () => import("@/view/pages/vuetify/forms/Selects.vue")
            },
            {
              path: "textareas",
              name: "vuetify-textareas",
              component: () =>
                import("@/view/pages/vuetify/forms/Textareas.vue")
            },
            {
              path: "text-fields",
              name: "vuetify-text-fields",
              component: () =>
                import("@/view/pages/vuetify/forms/TextFields.vue")
            },
            {
              path: "simple-tables",
              name: "vuetify-simple-tables",
              component: () =>
                import("@/view/pages/vuetify/tables/SimpleTables.vue")
            },
            {
              path: "data-tables",
              name: "vuetify-data-tables",
              component: () =>
                import("@/view/pages/vuetify/tables/DataTables.vue")
            },
            {
              path: "tabs",
              name: "vuetify-tabs",
              component: () => import("@/view/pages/vuetify/Tabs.vue")
            },
            {
              path: "timelines",
              name: "vuetify-timelines",
              component: () => import("@/view/pages/vuetify/Timelines.vue")
            },
            {
              path: "tooltips",
              name: "vuetify-tooltips",
              component: () => import("@/view/pages/vuetify/Tooltips.vue")
            },
            {
              path: "treeview",
              name: "vuetify-treeview",
              component: () => import("@/view/pages/vuetify/Treeview.vue")
            }
          ]
        },
        {
          path: "/wizard",
          name: "wizard",
          component: () => import("@/view/pages/wizard/Wizard.vue"),
          children: [
            {
              path: "wizard-1",
              name: "wizard-1",
              component: () => import("@/view/pages/wizard/Wizard-1.vue")
            },
            {
              path: "wizard-2",
              name: "wizard-2",
              component: () => import("@/view/pages/wizard/Wizard-2.vue")
            },
            {
              path: "wizard-3",
              name: "wizard-3",
              component: () => import("@/view/pages/wizard/Wizard-3.vue")
            },
            {
              path: "wizard-4",
              name: "wizard-4",
              component: () => import("@/view/pages/wizard/Wizard-4.vue")
            }
          ]
        }
      ]
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register")
        },
        {
          name: "forgetpassword",
          path: "/forget-password",
          component: () => import("@/view/pages/auth/ForgetPassword")
        },
        {
          name: "resetpassword",
          path: "/reset-password/:token",
          component: () => import("@/view/pages/auth/ResetPassword")
        },
       
      ]
    },
    {
      path: "/",
      component: () => import("@/view/employee/checkin_layout"),
      children: [
        {
          name: "checkin",
          path: "employee/checkin",
          component: () => import("@/view/employee/checkin")
        },
       
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/mailReview/review-layout"),
      children: [
        {
          name: "invoiceReview",
          path: "/invoice-review/:order_id",
          component: () => import("@/view/pages/mailReview/invoiceReview")
        },
        {
          name: "purchaseReview",
          path: "/purchase-review/:poId",
          component: () => import("@/view/pages/mailReview/purchaseReview")
        },
      ]
      },
     
      
    
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
